<script setup>
import kilifiBackgroundImage from "@/assets/images/beach.jpg";
import kirinyagaBackgroundImage from "@/assets/images/kiri_3.jpg";
import { countyIsKilifi, countyIsKirinyaga } from "@/utils/vue_helpers";
import { computed } from "vue";

const backgroundImage = computed(() => {
  let image = kilifiBackgroundImage;
  if (countyIsKilifi.value) {
    image = kilifiBackgroundImage;
  } else if (countyIsKirinyaga) {
    image = kirinyagaBackgroundImage;
  }

  return image;
});
</script>

<template>
  <div
    :class="{
      'primary-secondary-gradient': false,
      'pb-6 pt-md-12 px-md-12 pb-md-12': true,
    }"
    :style="{
      height: '100%',
      backgroundImage: $mobileBreakpoint ? null : `url(${backgroundImage})`,
      backgroundSize: 'cover',
    }"
  >
    <slot />
  </div>
</template>
