var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    'primary-secondary-gradient': false,
    'pb-6 pt-md-12 px-md-12 pb-md-12': true,
  },style:({
    height: '100%',
    backgroundImage: _vm.$mobileBreakpoint ? null : `url(${_setup.backgroundImage})`,
    backgroundSize: 'cover',
  })},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }