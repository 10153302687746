<script setup>
import SideMenu from "@/components/user/SideMenu.vue";
import BackButton from "../utils/BackButton.vue";
import GenericBackground from "@/components/layout/GenericBackground.vue";
import { userAuthenticated, useRouterFromInstance } from "@/utils/vue_helpers";
import { routes } from "@/router/routes";
import { computed, nextTick } from "vue";

const props = defineProps({
  links: {
    type: Array,
    default: null,
  },
  prevRoute: {
    type: Object,
    default: null,
  },
});

const { router } = useRouterFromInstance();

const backButtonProperties = computed(() => {
  const properties = {
    buttonText: "",
    onClick: () => {},
  };
  if (userAuthenticated.value) {
    properties.buttonText = "Home";
    properties.onClick = () => {
      router.push(routes.userAccountDetails);
    };
  } else {
    properties.buttonText = "Login";
    properties.onClick = () => {
      router.push(routes.login);
    };
  }
  return properties;
});

async function onCloseClicked() {
  if (!!props.prevRoute) {
    router.push(props.prevRoute);
  }
}
</script>

<template>
  <GenericBackground>
    <v-container fluid class="pa-0 pa-md-3">
      <v-row :no-gutters="$mobileBreakpoint">
        <v-col cols="12">
          <v-card
            :tile="$mobileBreakpoint"
            :flat="$mobileBreakpoint"
            :class="{
              'reduced-opacity-background': true,
            }"
          >
            <div class="d-flex align-stretch">
              <div class="d-flex flex-column align-start">
                <BackButton @click="backButtonProperties.onClick()">
                  {{ backButtonProperties.buttonText }}
                </BackButton>
                <SideMenu
                  v-if="!$mobileBreakpoint"
                  :links="links"
                  class="flex-grow-1"
                />
              </div>

              <div
                class="flex-grow-1 pl-3 py-2 pr-3 d-flex flex-column align-end"
                :style="{ minWidth: 0 }"
              >
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn icon @click="onCloseClicked" v-on="on" v-bind="attrs"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </template>
                  <span>Close</span>
                </v-tooltip>
                <router-view> </router-view>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </GenericBackground>
</template>
